import React, { Component } from 'react'
import classNames from 'classnames'
import { random } from '../../utils/utils'
    
const createLabel = (label, inputId, tooltips, toggleTooltips, showTooltips, type) => {
    if (type === 'checkbox')
        return <label className='checkbox-label' htmlFor={ inputId } title={ !toggleTooltips ? tooltips : 'checkbox label' }>{ label }</label>
    return <div className="form-input-label">
        <label title={ !toggleTooltips ? tooltips : '' }>{ label }</label>
        { tooltips && <div className="tooltips-container"> <span
            className="tax-status-icon tax-status-icon--tooltips form-input-tooltips"
            onClick={ toggleTooltips } />
            { showTooltips && <div className="form-input-tooltips--view" dangerouslySetInnerHTML={ { __html: tooltips } }></div> }
        </div> }
    </div>
}

const isDefined = val => val !== undefined && val !== ''

class FormElementBase extends Component{
    constructor(props) {
        super(props)
        this.state = { showTooltips: this.props.showTooltips, isEditing: false }
        this.toggleTooltips = this.toggleTooltips.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
    }

    toggleTooltips() {
        this.setState({ showTooltips: !this.state.showTooltips }, () => {
            if (this.state.showTooltips) {
                this.timerId = setTimeout(() => this.toggleTooltips(), 3000)
            } else {
                clearTimeout(this.timerId);
                this.timerId = null;
            }
        })
    }

    onKeyDown(event) {
        if (this.state.value && this.props.onSubmit && [13, 39].includes(event.keyCode)) {
            this.props.onSubmit(this.state.value);
        }
    }

    componentDidMount () {
        if (this.props.onChange && isDefined(this.props.defaultValue)) {
            // set the default value on store
            this.props.onChange(this.props.defaultValue, false)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.onChange && this.props.value != undefined && nextProps.value == undefined)  {
            // set the default value on store
            this.props.onChange(undefined, false)
        }
        if (this.props.onChange && this.props.defaultValue == undefined && nextProps.defaultValue != undefined)  {
            // set the default value on store
            setTimeout(() => this.props.onChange(nextProps.defaultValue, false), 500)
        }
    }

    render() {        
        const isEditing = this.state.isEditing
        const { infotext, name, onChange, onBlur, valid, invalid, msg, placeholder, label, tooltips, type='text', defaultValue, value, enableChange=true, children, cssClass='', inputCssClass, disabled, readonly, decoration } = this.props
        const helpTxt = label || name
        const showTooltips = this.state.showTooltips
        const containerCssClass = classNames(`input-line-group tax-input--${type} ${ cssClass }`, cssClass, { 'is-editing': isEditing }, { 'validate-err': invalid }, { 'validate-ok': valid })
        const inputClz = classNames('form-input-elm', inputCssClass, { 'validate-err': invalid }, { 'validate-ok': valid })
        const inputId = 'tax-input-'+ random();        
        

        let childProps = {
            id: inputId,
            name: name,
            type: type,
            className: inputClz,
          //placeholder: placeholder? placeholder : '' ,
            autoComplete: type === 'password'? 'off' : 'on',
            disabled: disabled,
            readOnly: readonly,
            onFocus: e => type != 'checkbox' && this.setState({ isEditing: true}),
            onBlur: (e) => { 
                if (type != 'checkbox') {
                    this.setState({ isEditing: false});
                    onBlur && onBlur(e.target.value)
                    }
                },
            onChange: val => { enableChange && onChange && onChange(val.target? (type == 'checkbox'? val.target.checked : val.target.value) : val); },
            onKeyDown: this.onKeyDown
        }
        const val = isDefined(value)? value : ''
        if (type == 'checkbox')
            childProps.checked = [1, "1", "true", "on", "checked", true].includes(val)
        else
            childProps.value = val
        return <div className={ containerCssClass }>
            { type != 'checkbox' && createLabel(helpTxt, inputId, tooltips, this.toggleTooltips, showTooltips ) }
            { React.cloneElement(children, childProps) }
            { type == 'checkbox' && createLabel(helpTxt, inputId, tooltips, this.toggleTooltips, showTooltips, type ) }

            { msg && <div className={ `${isEditing? "hint" : "error"}-msg input-validate-msg` }>{ msg.map( m => <span key={ m }>{ m }</span> ) }</div> }
            { decoration && React.cloneElement(decoration) }
            { (!msg && infotext) &&
              <span className={'info-text'}>{infotext}</span>
            }
        </div>
    }
}

export default FormElementBase;
  