import { useWizard } from 'react-use-wizard';
import txt from '../../utils/i18n'

const steps = () => [{
	name: 'taxintro',
	desc: txt('TXT_STEP_INTRO_DESC')
},{
	name: 'taxpayer',
	desc: txt('TXT_STEP_PAYER_DESC')
},{
	name: 'sponsorship',
	desc: txt('TXT_STEP_SPONSOR_DESC')
},{
	name: 'result',
	desc: txt('TXT_STEP_RESULT_DESC')
}]

export default function Header() {
	const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();

  return (<div className='wizard-header-container'>
  		<div className='progressbar-container'>
  			<div className='progress-finished' style={{'width': `${(activeStep+1)/stepCount*100}%`}}></div>
  			<div className='progress-remaining' style={{'width': `${(stepCount-activeStep-1)/stepCount*100}%`}}></div>
  		</div>
  		<div className='wizard-hdr-steps'>
  			{
  				steps().map((s, idx) => {
  					return (
	  					<div key = { s.name } className={`wizard-hdr-step wizard-step-${s.name} ${ idx < activeStep? ' checked' : ''} ${ idx == activeStep? ' active' : ''}`}>
	  						<div className='step-icon-container'>
	  							<span onClick={ (e) =>  idx < activeStep && goToStep(idx) } className={`step-icon step-icon-${s.name} ${ idx < activeStep? ' checked' : ''} ${ idx == activeStep? ' active' : ''}`}>
	  								<i className={`wi-${s.name} ${ idx < activeStep? ' checked' : ''} ${ idx == activeStep? ' active' : ''}`}></i></span>
	  							<span className={`step-title ${ idx < activeStep? ' checked' : ''} ${ idx == activeStep? ' active' : ''}`}>{s.desc}</span>
	  						</div>
	  					</div>
  					)
  				})
  			}
  		</div>
  	</div>)
}