import React from 'react'
import PropTypes from 'prop-types';
import txt from '../../utils/i18n'

const normalizeFormData = (formData, extras, excludes) => excludes.reduce((total, e) => {
    delete total[e]
    return total
}, { ...formData, ...extras })

export const handleSubmit = (validateForm, formData, submit, extras={}, excludes=[]) => {
    if (!validateForm()) {
        submit(normalizeFormData(formData(), extras, excludes))
    }
}

const FormButtons = ({ btnTxt, validateForm, formData, submit, extras={}, excludes=[], withCancel, onClick, loading }) => {
  return (
    <div className="form-action-line">
      { withCancel &&
        <a href='#' className="btn btn-secondary" onClick={(e) => { e.preventDefault(); withCancel && withCancel(); } }>
          { txt('TXT_BACK') }
        </a>
      }
        <button
            className={`btn btn-primary ${loading? "loading" : ""}`}
            onClick={ () => { !loading && onClick && onClick(); handleSubmit(validateForm, formData, submit, extras, excludes) } } >
            { btnTxt }
        </button>
    </div>
  )
};

FormButtons.propTypes = {
    btnText: PropTypes.string.isRequired,
    formData: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
}

export default FormButtons