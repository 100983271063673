import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'

const Expandable = ({ toggler, children, expanded=false, clzName='', title='' }) => {
	const [unfolded, setUnfolded] = useState(expanded)

    return <div className={ classNames('expandable-row', clzName, { 'expanded': unfolded }) }>
      <a className={ classNames('expandable-row-header', {'expanded': unfolded}) } onClick={ () => setUnfolded(!unfolded) } href="#" title={ title } >
        { toggler }
      </a>
      { unfolded && <div className="expanded-details"> { children } </div> }
    </div>;
}

Expandable.propTypes = {
  toggler: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  expanded: PropTypes.bool
};

export default Expandable;
