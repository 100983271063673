import React, { Component, PropTypes } from 'react'
import FormElementBase from './form-elm-base'
import { random } from '../../utils/utils'

const cbId = 'cb-'+random()
const Checkbox = ({ ...props }) => {
	return (
		<FormElementBase type="checkbox" { ...props } >
			<input type="checkbox" id={ cbId }  /> 
		</FormElementBase>
	)
}
export default Checkbox;