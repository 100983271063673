

export const random = (length = 5) => String(new Date().getTime()).substr(-1*length)

export const isSame = (objA, objB) => {
    if (typeof objA === 'object' && typeof objB === 'object' && Object.keys(objA).length === Object.keys(objB).length) {
        return !Object.keys(objA).some(k => objA[k] != objB[k])
    }
}

//startsWith doesn't work with IE 11
export const normalizeUrl = (urlStr) => urlStr && urlStr.length > 0 && (urlStr.indexOf('http') == 0? urlStr : window.location.origin+(urlStr.indexOf('/') == 0? urlStr : '/' + urlStr)) ||  window.location.origin

export const sameUrl = (urlA, urlB) => {
    urlA = normalizeUrl(urlA)
    urlB = normalizeUrl(urlB)

    return urlA == urlB
}

export const obj2FormData = obj => Object.keys(obj).reduce((total, k) => {
    if (total.length) {
        total += '&'
    }
    total += `${k}=${window.encodeURIComponent(obj[k])}`
    return total
}, '')

export const uriEncodeJson = obj => {
    Object.keys(obj).forEach( k => {
        obj[k] = window.encodeURIComponent(obj[k])
    })
    return obj
}

export const base64EncodedFormData = obj => window.btoa(obj2FormData(obj))

export const formatAccount = accNumber => {
    if (!accNumber || String(accNumber).length === 0) return accNumber
    let nr = '', index = 0;
    [5, 2, undefined].some(len => {
        let part = String(accNumber).substr(index, len)
        if (part.length){
            if (nr) nr += ' '
            nr += part
        }
        if (part.length < len) return true
        index += len
    })
    return nr
}

export const normalizeValue = val => val && String(val).replace(/[\s|,|.]/g, '')

export const psAccepted = () => {
    let match = document?.cookie.match(new RegExp('PS=([^;]+)'));
    return match && 'accepted' ==  match[1]
}

export const hashCode = str => {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        let chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
