import React from 'react'
import PropTypes from 'prop-types';
import TextInput from '../form/textinput'
import Checkbox from '../form/checkbox'
import FormButtons, { handleSubmit } from '../form/form-buttons'
import VippsPayment from './vipps-payment'
import txt from '../../utils/i18n'

const mobileAdapter = ({ onChange, onBlur, value, ...props} = {}) => ({
    onBlur: val => onBlur(val.replace(/[\s\*]/g, '')),
    onChange: val => onChange(val.replace(/[\s\*]/g, '')),
    value: '*** ** '+value,
    ...props
})

const VippsForm = ({ validateForm, formData, submit, withCancel, loading, ...props }) => {
    return <div className="tax-vipps-form">
        <div className="form-input-lines">
            <TextInput
                autoFocus
                name="mobile"
                label={ txt('TXT_INPUT_MOBILE_LABLE') }
                placeholder={ txt('TXT_INPUT_MOBILE_HINTS') }
                { ...mobileAdapter(props.mobile) }
                defaultValue={ '*** ** ' } />
        </div>
        <VippsPayment />
        <div className="form-input-lines">
            <Checkbox
                name="payment"
                label={ txt('TXT_INPUT_VIPPS_LABLE') }
                { ...props.payment }
                defaultValue={ '' } />        
        </div>
        <FormButtons
            validateForm={ validateForm }
            formData={ formData }
            submit={ submit }
            loading={ loading }
            btnTxt={ txt('TXT_BUTTON_NEXT') }
            withCancel={ withCancel }
        />
    </div>
}

VippsForm.propTypes = {
    submit: PropTypes.func.isRequired
}

export default VippsForm