import { taxPost, taxFetch } from '../utils/ajax.js'

export async function searchTaxee(payerInfo) {
	if (payerInfo && Object.keys(payerInfo).length >= 3)
		return await(await(taxPost('/api/tax/search', payerInfo)))
}

export async function searchTax(payerInfo) {
	if (payerInfo && Object.keys(payerInfo).length >= 3 && payerInfo.taxee_id)
		return await(await(taxPost('/api/tax/search?taxee='+payerInfo.taxee_id, payerInfo)))
	throw new Error('Invalid Payer Info: '+payerInfo)
}

export function startWizard(wizard) {
	taxPost('/api/start/wizard', { wizard })
}

export function doPay(mobile) {
	taxPost('/api/pay', { mobile })
}

export function changeLang(cc) {
	taxFetch('/api/change/lang?nl='+cc)
}

export function acceptCookies() {
	taxFetch('/api/accept/cookies')
}

