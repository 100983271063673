import { TXPrivacyStatment } from './privacy-statement'
import txt from '../utils/i18n'
import DangerHtml from './danger-html'


const aboutUs = () => <DangerHtml html={ txt('TXT_HTML_ABOUT_US') } />

const termsConditions = () => <DangerHtml html={ txt('TXT_HTML_USER_TERMS') } />

const Footer = ({ title, hostname, showModal }) => {
	return <footer className='social-media-sharing'>
		<div>
			<span> 
			<a className='sms-link sms-twitter' target='_blank' href={ `https://twitter.com/intent/tweet?url=https%3A%2F%2F${hostname}&text=${title}` }>
			<i className='sms-twitter'> </i>
			</a>
			</span>
			<span> <a className='sms-link sms-fb' target='_blank' href={ `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${ hostname }&text=${ title }` }>
			<i className='sms-fb'> </i>
			</a>
			</span>
			<span> <a className='sms-link sms-linkedin' target='_blank' href={ `https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2F${hostname}&text=${title}` }>
			<i className='sms-linkedin'> </i>
			</a>
			</span>
			<span> <a className='sms-link sms-pinterest' target='_blank' href={ `https://pinterest.com/pin/create/button/?url=https%3A%2F%2F${hostname}&description=${title}` }>
			<i className='sms-pinterest'> </i>
			</a>
			</span>
		</div>
		<div className='txs-links'>
			<a href='#' onClick={ () => showModal(termsConditions()) }>Bruksvilkår</a>
			<a href='#' onClick={ () => showModal(TXPrivacyStatment()) }>Personvernerklæringen </a>
			<a href='#' onClick={ () => showModal(aboutUs())}>Om oss</a>
			<a href='#'>Kontakt oss</a>
		</div>
	</footer>
}

export default Footer