import React, { useState } from 'react'
import TaxWizard from '../components/wizard'
import Header from '../components/header'
import Footer from '../components/footer'
import PrivacyStatement from '../components/privacy-statement'
import ModalView from '../components/modal-view'
import txt, { current } from '../utils/i18n'
import { psAccepted } from '../utils/utils'
import { doPay, searchTax, searchTaxee, changeLang, acceptCookies, startWizard } from '../services/tax-service'

const hostname = window.location.hostname

export default function App() {
	const [curLang, setCurLang] = useState(current())
	const [modal, showModal] = useState(null)
	const [showPrivacy, setShowPrivacy] = useState(!psAccepted())
	const title = txt('TAX_INFO_TITLE')
	return (
		<>
			<Header changeLang = { (nl) => { changeLang(nl); setCurLang(current(nl)); } } title = { title } />
			<TaxWizard doPay = { doPay } search = {{ searchTax, searchTaxee }} showModal={ showModal } startWizard = { startWizard } />
			<Footer hostname = { hostname } title = { title } showModal={ showModal } />
			<ModalView dismissModal={ () => showModal(null) }>
				{ modal }
			</ModalView>
			{ showPrivacy && <PrivacyStatement 
							accept = { async () => {
						      await acceptCookies()
						      setShowPrivacy(false) } 
						  	}
							close = { async () => {
						      await acceptCookie()
						      setShowPrivacy(false) } 
						  	}
						  	showModal={ showModal } /> }
		</>
	)
}