import { useState } from 'react'
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import VippsForm from './vipps-form'
import ReactForm from 'react-form-ex'
import { vippsValidators } from './validators'
import MessageView from '../message-view'
import txt from '../../utils/i18n'


const VippsStep = ({ searchTaxee, ...props }) => {	
	const [status, setStatus] = useState({loading: 0})
	const { isFirstStep, previousStep, nextStep } = useWizard();

    return <div className="wizard-step wizard-step-vipps">
        <h1>
            { txt('TXT_STEP_VIPPS_HEADER') }
        </h1>
        <div className='self-decided-sponso'>{ txt('TXT_VIPPS_DONATION_EXP') }</div>
        <ReactForm validators={ vippsValidators() } lang={ props.curLang }>
            <VippsForm  submit={ async (obj) => {
            	setStatus({loading:1})
            	let res = await searchTaxee(obj)
            	console.log('searchTaxee res : ', res, res.msg_id)
            	let loading = (res.status == 500 || res?.error?.includes("Internal Server Error")? -1 : 0)
            	setStatus({loading, msg_id: (res.msg_id || 'ERR_SEARCH_TAX_MSG')})
            	if (loading == 0) 
            		nextStep()
            	} } 
            	loading = { status.loading === 1 }
            	withCancel={ !isFirstStep? ()=>previousStep() : null } />
        </ReactForm>
        { status.loading == -1 && <MessageView message={ {type:'error', msg: txt(status.msg_id)} }></MessageView> }
    </div>
}

VippsStep.propTypes = {
    searchTaxee: PropTypes.func.isRequired
}

export default VippsStep