const SplitText = ({ text, role, start=0.5, step=1, keepLast=false }) => {
	const ary = Array.isArray(text)? text : text.split('')
    return(
        <span className='animated-container' aria-label='split-text' role={ role }>
          {          	
            ary.map((char, index, elms) => {
            let style = {"animationDelay": (start + index / 10 * step)+ "s"}
            if (keepLast && index == elms.length-1)
            	style = {"animationDelay": (start + index / 10 * step)+ "s", "animationFillMode": "backwards",}
            return <span
              className="animation-item"
              aria-hidden="true"
              key = { index }
              style = { style }>
              { char }
            </span>;
          })
         }
        </span>
    )
}

export default SplitText