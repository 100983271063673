import React from 'react'
import PropTypes from 'prop-types';
import TextInput from '../form/textinput'
import FormButtons, { handleSubmit } from '../form/form-buttons'
import txt from '../../utils/i18n'

const TaxPayerForm = ({ validateForm, formData, submit, ...props }) => {
    return <div className="tax-payer-form">
        <article className='input-lines-container'>
            <div className="form-input-lines">
                <TextInput
                    autoFocus
                    name="firstName"
                    label={ txt('TXT_INPUT_FIRSTNAME_LABLE') }
                    placeholder={ txt('TXT_INPUT_FIRSTNAME_HINTS') }
                    { ...props.firstName }
                    defaultValue={ '' } />
                <TextInput
                    name="lastName"
                    label={ txt('TXT_INPUT_LASTNAME_LABLE') }
                    placeholder={ txt('TXT_INPUT_LASTNAME_HINTS') }
                    { ...props.lastName }
                    defaultValue={ '' } />
                <TextInput
                    name="birthYear"
                    label={ txt('TXT_INPUT_BIRTHYEAR_LABLE') }
                    placeholder={ txt('TXT_INPUT_BIRTHYEAR_HINTS') }
                    { ...props.birthYear }
                    defaultValue={ '' } />
            </div>
            <div className="form-input-lines">
                <TextInput
                    name="taxPlace"
                    label={ txt('TXT_INPUT_TAXPLACE_LABLE') }
                    placeholder={ txt('TXT_INPUT_FIRSTNAME_HINTS') }
                    { ...props.taxPlace }
                    defaultValue={ '' } />
                <TextInput
                    name="postPlace"
                    label={ txt('TXT_INPUT_POSTPLACE_LABLE') }
                    placeholder={ txt('TXT_INPUT_LASTNAME_HINTS') }
                    { ...props.postPlace }
                    defaultValue={ '' } />
            </div>
        </article>
        <FormButtons
            validateForm={ validateForm }
            formData={ formData }
            submit={ submit }
            btnTxt={ txt('TXT_BUTTON_CONFIRM') }
            withCancel={ false }
        />
    </div>
}

TaxPayerForm.propTypes = {
    submit: PropTypes.func.isRequired
}

export default TaxPayerForm