
let _configs = { contextRoot: '/api' }

const doFetch =(url, options) => {
    return fetch(url, { ...options, redirect: 'follow' })
    .then(response => {
        console.log('doFetch response = ', response)
        const xsrf = response.headers.get('X-XSRF-TOKEN');
        xsrf && window.sessionStorage.setItem('xsrf', xsrf)
        if (_configs.proxy && _configs.proxy.response.length && _configs.proxy.response.some((p) => p(response, url))) {
            return Promise.resolve({
                json:{},
                response: { ok: true }
            })
        }
        const contentType = response.headers.get('Content-Type') || '';
        if (contentType.includes('application/json')) {
            return response.json().then(json => ({ json, response }))
        } else if (response.ok && contentType.includes('text/html')) {
            return response.text().then(html => ({ json: { _INT_HANDLE_: true, html }, response } ))
        }
        return Promise.reject('Invalid response content type')
    })
  .then(({ json, response }) => {
    if (!response.ok) {
      console.error('got error from server', json, response)
      let error = Object.assign({}, json, { status: response.status});
      return Promise.reject(error);
    }
    return { response:json };
  })
  .then(
    response => { return { ...response }; },
    error => ({ ...error })
  )
}

const requestBuilder = (method, body, headers={}) => ({
    method,
    body: typeof body === 'object' && JSON.stringify(body) || body,
    credentials: 'include',
    headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
        ...headers,
        'X-XSRF-TOKEN': sessionStorage.getItem('xsrf') || ''
    })
})

const absPath = (path='') => path.indexOf('/') === 0 || path.indexOf('http') === 0

const taxFetch = (api) => {
    const url = absPath(api) ? api :  `${_configs.contextRoot}/no/tax/${api}`;
  return doFetch(url, {
    method: 'GET',
    credentials: 'include'
  })
}

const taxPost = (api, body = {}, headers={}) => {
  const url = absPath(api) ? api :  `${_configs.contextRoot}/no/tax/${api}`;
  return doFetch(url, requestBuilder('POST', body, headers))
}

const taxPut = (api, body = {}) => {
    const url = absPath(api) ? api :  `${_configs.contextRoot}/no/tax/${api}`;
    return doFetch(url, requestBuilder('PUT', body))
}

const taxDelete = (api, body = {}) => {
    const url = absPath(api) ? api :  `${_configs.contextRoot}/no/tax/${api}`;
    return doFetch(url, requestBuilder('DELETE', body))
}

const initAjax = (configs={}) => { _configs = { ..._configs, ...configs } }

export { initAjax as default, taxFetch, taxPost, taxPut, taxDelete }
