import PropTypes from 'prop-types'
import Modal from 'react-modal'

Modal.setAppElement('#root')

export const defaultStyle = {
    overlay : {
        position          : 'fixed',
        top               : 0,
        left              : 0,
        right             : 0,
        bottom            : 0,
        backgroundColor   : 'rgba(125, 125, 125, 0.75)'
    },
    content : {
        top               : '50%',
        left              : '50%',
        right             : 'auto',
        bottom            : 'auto',
        maxHeight         : '80%',
        padding           : '30px',
        overflow          : 'auto',
        maxWidth 		  : '800px',
        marginRight       : '-50%',
        transform         : 'translate(-50%, -50%)'
    }
};

const ModalView = ({ children, contents, cssClassName='', customStyle, dismissModal }) => {
    return <Modal
        isOpen={ children || contents }
        onAfterClose = { dismissModal }
        style={ { ...defaultStyle, ...customStyle } } >
            { children || contents }
            <div style={{ marginTop: "38px", textAlign: "center"}} >
                  <a className='modal-close-btn btn btn-primary'
                  onClick={ () => dismissModal() }>Lukk </a>
            </div>
    </Modal>
}

ModalView.propTypes = {
    isOpen: PropTypes.bool,
    contents: PropTypes.node,
    customStyle: PropTypes.object,
    cssClassName: PropTypes.string,
    dismissModal: PropTypes.func
}

export default ModalView
