import { useState, useEffect } from 'react'
import { useWizard } from 'react-use-wizard';
import SplitText from '../split-text'
import txt, { current } from '../../utils/i18n'

const IntroStep = ({ ...props }) => {
    const [buttonText, setButtonText] = useState('TXT_BUTTON_JUMP'); 

    // the effect
    useEffect(() => {
      setTimeout(() => setButtonText('TXT_BUTTON_SEARCH_ANON'), 20000)
    }, [current()])
    const {
        nextStep
    } = useWizard();


const conversations = () => txt('TXT_TAX_INTRO_CONVERSATIONS').split('\n')

const pictures = [
<div className='play-img-container'><img src='/assets/images/byd.jpg' alt='Stort enebolig, helt ny BYD Tang elbil' /></div>,
<div className='play-img-container'><img src='/assets/images/tax-search.jpg' alt='Søkt skatte inntekt på www.skatteetaten.no' /></div>,
<div className='play-img-container'><img src='/assets/images/angry.jpg' alt='Naboene har blitt sure' /></div>,
<div className='play-img-container'><img src='/assets/images/taxee.jpg' alt='TAXEE.NO er Proxy mellom deg og skatteetaten' /><p className='taxee-proxy-exp'dangerouslySetInnerHTML={{ __html: txt('TXT_TAXEE_PROXY_EXP') }}></p></div>
]

console.log('jump btn txt: ', txt('TXT_BUTTON_SEARCH_ANON'))
    return <div className='wizard-step wizard-step-intro'>
        <h1>
            { txt('TAX_STEP_INTRO_HEADER') } 
        </h1>
        <div className='tax-scene-play'>
            <div className='play-scripts'>
                <SplitText text={ conversations() } role='play-scripts' step='30'></SplitText>
            </div>
            <div className='play-pictures'>
                <SplitText text={ pictures } role='play-pictures' start='0' keepLast='true' step='80'></SplitText>
            </div>            
        </div>
        <div className='btn-lines'>
            <button className='btn tax-intro-start' type="button" onClick={ (e) => { e.preventDefault(); nextStep(); } }>{ txt(buttonText) }</button>
        </div>
    </div>
}

export default IntroStep