let _texts, _cl

export const current = (cl) => {
    if (cl) {
        _cl = cl
        return _cl
    }
    else {
        if (!_cl) {
            let match = document.cookie && document.cookie.match(new RegExp('curlang=([^;]+)'));
            _cl = match && match[1] || 'no' 
        }
        return _cl   
    }
    
}

export const initI18NO = (...lngFiles) => _texts = lngFiles
    .reduce((langs, l) => ({
        ...langs,
        ...l[_cl]
}), {})

export const initI18N = (...lngFiles) => _texts = lngFiles
    .reduce((langs, lf) =>
        Object.keys(lf).reduce((langList, langcode) => ({
            ...langList,
            [langcode]: {
                ...langList[langcode],
                ...lf[langcode]
            }
        }), langs) , {})

export const locales = () => {
    if (!_texts || Object.keys(_texts).length === 0) {
        throw new Error('i18n has to be initiated before use')
    }
    return {
        curLang: _cl,
        langs: Object.keys(_texts).map(lc => ({ cc: lc, name: _texts[lc]['__name__'] || ''}))
    }
}

const txt = (key, ...formatters) => {
    if (!_texts) { return key}
    if (Object.keys(_texts).length === 0) {
        throw new Error('i18n has to be initiated before use')
    }
    let text = _texts[_cl][key], idx = 0
    //no.TXT_SHARE_BUY_CONFIRM = "Are you sure that you will buy %s shares at price of %s"
    return text && text.replace(/(%s)/gi, () => formatters[idx++]) || 'NO KEY:'+key
}

export default txt