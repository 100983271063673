import React from 'react'
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import TaxPayerForm from './taxpayer-form'
import ReactForm from 'react-form-ex'
import { taxpayerValidators } from './validators'
import txt from '../../utils/i18n'


const TaxPayerStep = ({ setTaxPayer, ...props }) => {
	const {
	    isLastStep,
	    isFirstStep,
	    activeStep,
	    stepCount,
	    previousStep,
	    nextStep,
	    handleStep,
	} = useWizard();

	handleStep(() => {
    	//a callback to be called when nextStep() is called
    	//alert('done with tax payer info')
  	});

    return <div className="wizard-step wizard-step-payer">
        <h1>
            { txt('TXT_STEP_PAYER_HEADER') }
        </h1>
        <ReactForm validators={ taxpayerValidators() } lang={ props.curLang }>
            <TaxPayerForm  
            	submit={ obj => {
	            	//store the tax payer info in app store
	            	setTaxPayer(obj)
	            	nextStep()
	            } }
	            withCancel={!isFirstStep}  
            />
        </ReactForm>
    </div>
}

TaxPayerStep.propTypes = {
    setTaxPayer: PropTypes.func.isRequired
}

export default TaxPayerStep