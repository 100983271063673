import { useState } from 'react'
import PropTypes from 'prop-types';
import Expandable from '../expandable'
import txt from '../../utils/i18n'

const fields = ["birthYear", "postCode", "postPlace", "earnings", "tax", "assets", "year", "taxPlace"]

const TaxInfo = ({ tax, expanded, searchTax }) => { 
    const [loading, setLoading] = useState(false)
    console.log('TaxInfo render:', tax, loading)
    return <Expandable 
        expanded={ expanded } 
        toggler={ <div className="tax-info-header"> { 
            ['fullName', 'birthYear', 'postPlace']
            .map(f => <span className={ "toggler-field toggler-field--"+f.toLowerCase() } key={ f }>{ tax[f] }</span>) } 
         </div> }>
        <div className="expanded-details-view"> {
            fields.map(f => <div className={'tax-info-line tax-info-line--'+f} key={ f }>
                <span className='field-name'>{ txt('TXT_TAX_LBL_'+f.toUpperCase()) }</span>
                <span className='field-value'>{ tax[f] }</span>
            </div>) }
            { !tax.hasOwnProperty('earnings') && <div className="form-action-line">
            <button className={`btn btn-info small ${loading? "loading" : ""}`} onClick={ 
                async () => {
                    if (loading) return
                    setLoading(true);
                    let res = await searchTax(tax);
                    console.log('TaxInfo fetched tax: ', res, tax)
                    setLoading(false);
                } }>
                { txt('TXT_BUTTON_FETCH_TAX') }
            </button>
        </div>

            }
        </div>
    </Expandable>
}

TaxInfo.propTypes = {
    tax: PropTypes.object.isRequired
}

export default TaxInfo