import txt, { locales } from '../utils/i18n'

const transLang = () => {
	const slangs = locales()
	return slangs.langs.filter(c => c.cc != slangs.curLang)[0]
}

const Header = ({ changeLang, title }) => {
	const newLang = transLang()
	console.log('newLang = ', newLang)
	return (
		<header>
			<h1 className='tax-header'>
				<img className='taxee-logo' src='/assets/images/logo.svg' />
				{ title } 
				<span className='tax-lang'
					title={ `Change to ${newLang.name}` } 
					onClick={ () => changeLang(newLang.cc) }>
					<img className='language-icon' src='/assets/images/language.svg' />
					<span className='language-label'>{ newLang.name }</span>
				</span>
			</h1>
		</header>
	)
}

export default Header