import txt from '../utils/i18n'
import DangerHtml from './danger-html'


export const TXPrivacyStatment = () => <DangerHtml html={ txt('TXT_HTML_PRIVACY_STATEMENT') } />


const PrivacyStatement = ({ accept, close, showModal }) => {
    return <div className='privacy-statement-sec'>
            <span className='cookie-disclaim'> 
                { txt('TXT_PRIVACY_STATEMENT_DISC') }
                <a className='privacy-statement' href='#' onClick={ () => showModal(TXPrivacyStatment()) }> { txt('TXT_PRIVACY_STATEMENT') } </a>
            </span>
            <span className='ps-buttons'>
                <a className='btn btn-secondary' href='#' onClick={ () => accept() }> { txt('TXT_BUTTON_CLOSE') } </a>
                <a className='btn btn-primary' href='#' onClick={ () => accept() }> { txt('TXT_BUTTON_ACCEPT_ALL') } </a> 
            </span>
        </div>
}

export default PrivacyStatement

      