import txt from '../../utils/i18n'
import SplitText from '../split-text'

export default function VippsPayment() {
	return <div className="form-input-lines">
    <h4 className="payment-header">{ txt('TXT_STEP_VIPPS_SPONSOR_HEADER') }</h4>
    <section className='vipps-sponsor-sec'> 
    <div className="payment-means">
        { txt('TXT_STEP_VIPPS_SPONSOR_QRCODE') } :
        <div className='vipps-qr-code img-wrapper'>
            <img src='/assets/images/payme.jpg' className='hover-zoom' />
        </div>
    </div>
    <div className="payment-means">
        { txt('TXT_STEP_VIPPS_SPONSOR_MOBILE') }
        <span className='vipps-mobile-nr'>
            <SplitText text = '984 09 395' role = 'Heading'></SplitText>
        </span>
    </div>
    </section>
</div> }