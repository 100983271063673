import {builtinValidators as Validators, createValidator} from 'simple-form-validator'
import txt from '../../utils/i18n'

const mobileRegExpNor = /^\d{3}$/
const birthYearRegEx = /^\d{4}$/

export const taxpayerValidators = () => ({
    firstName: [
        Validators.isRequired(txt('ERR_NO_EMPTY_FIELD')),
        Validators.length(txt('ERR_VALIDATE_MSG_NAME_LENGTH'), 2, 50)
    ],
    lastName: [
        Validators.isRequired(txt('ERR_NO_EMPTY_FIELD')),
        Validators.length(txt('ERR_VALIDATE_MSG_NAME_LENGTH'), 2, 50)
    ],
    birthYear: [
        Validators.isRequired(txt('ERR_NO_EMPTY_FIELD')),
        Validators.range(txt('ERR_VALIDATE_MSG_BIRTHYEAR'), [new Date().getFullYear()-120, new Date().getFullYear()-18])
    ],
    taxPlace: [],
    postPlace: []
})

export const vippsValidators = () => ({
    mobile: [
        Validators.isRequired(txt('ERR_NO_EMPTY_FIELD')),
        Validators.length(txt('ERR_VALIDATE_MSG_MOBILE_LENGTH'), 3),
        //Validators.range(txt('ERR_VALIDATE_MSG_MOBILE_LENGTH'), [0, 999])
    ],
    payment: [
        Validators.isRequired(txt('ERR_VALIDATE_MSG_VIPPS_PAYMENT'))
    ]
})