import React from 'react'
import PropTypes from 'prop-types';

const MessageView = ({ message, children }) => {
	return <div
		className={ `message-view-panel message-view-panel--${message.type.toUpperCase() } message-scope--${message.scope || 'app'}` } >
		{ message.header && <div className="message-header">{ message.header }</div> }
		{ message.items && message.items.map(item => <div className="message-view-item" key={ item._id }>{ item.msg }</div>) }
		{ !children && message.msg && <div className="message-view-item" dangerouslySetInnerHTML={ { __html: message.msg } }></div> }
		{ children && <div className="message-view-item">{ children }</div> }
	</div>
}

MessageView.propTypes = {
  message: PropTypes.object.isRequired
}

export default MessageView