import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Wizard, useWizard } from 'react-use-wizard'
import Header from './header'
import TaxIntro from './step-intro'
import TaxPayerStep from './step-taxpayer'
import VippsStep from './step-vipps'
import ResultStep from './step-result'
import { hashCode } from '../../utils/utils'

/*

	const taxInfo = useMemo(async () => { // Calls your function and caches its result
		console.log('useMemo taxInfo is being called')
	    let tax = await searchTax(taxPayer)
		// some extra process
		return tax
	  }
	, [taxPayer]);
*/

const normalizeTax = taxees => {
	return taxees.map(t => {
		const tStr = JSON.stringify(t)
		console.log('taxee info', t, tStr);
		if (!t.hasOwnProperty('earnings') && !t.hasOwnProperty('taxee_id')) {
			t.taxee_id = hashCode(tStr);
		}
		t.postPlace = t.postPlace?.trim().replace(/^\d+\s+(\w)/, "$1")
		t.taxPlace = t.taxPlace.split('-').at(0).trim()
		return t;
	});
}

export default function TaxWizard({ startWizard, doPay, showModal, search }) {
	const [taxPayer, setTaxPayer] = useState({});
	const [taxInfo, setTaxInfo] = useState({});

	useEffect(() => {
		console.log('starting Wizard ...')
		startWizard('celinaqs')
	}, [])

	const taxeeSearch = useCallback(async (obj) => {
	  console.log('taxeeSearch Obj: ', obj)
      await doPay(obj.mobile)
      await new Promise(r => setTimeout(r, 200));
      const taxee = await search.searchTaxee(taxPayer)
      console.log('TaxWizard fetched taxeeInfo = ', taxee, taxInfo) 
	  if (!taxee?.error?.includes("Internal Server Error") && taxee.status != 500) {
	  	const ntaxee = normalizeTax(taxee.response);
	  	setTaxInfo(ntaxee);
	  	return ntaxee
	  }   	
      return taxee;
    }, [taxPayer]);

    const taxSearch = useCallback(async (obj) => {
	  console.log('taxSearch Obj: ', obj)
      const taxRes = await search.searchTax(obj)
      console.log('TaxWizard fetched taxRes = ', taxRes, taxInfo)
      let nti = taxInfo
	  if (!taxRes?.error?.includes("Internal Server Error") && taxRes.status != 500) {
	  	const tax = taxRes.response.at(0)
	  	nti = taxInfo.map(t => {
	  		if (t.taxee_id == obj.taxee_id && tax.hasOwnProperty('earnings'))
	  			return tax;
	  		return t;
	  	});
	  	console.log('new iterated tax info:', nti)
	  	setTaxInfo(nti);
	  }
      return nti
    }, [taxInfo]);

    console.log('TaxWizard render loading = ', taxInfo)

	return (<article className='tax-wizard-container'>
		<Wizard 
			startIndex={ 0 }
			header={ <Header /> } >
			<TaxIntro />
		    <TaxPayerStep setTaxPayer ={ setTaxPayer } />
		    <VippsStep searchTaxee = { taxeeSearch } />
		    <ResultStep taxInfo = { taxInfo } searchTax = { taxSearch } showModal={ showModal } />
	  	</Wizard>
  	</article>
  )
}