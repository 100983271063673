import React from 'react'
import PropTypes from 'prop-types';
import { useWizard } from 'react-use-wizard';
import TaxInfo from './tax-info'
import MessageView from '../message-view'
import VippsPayment from './vipps-payment'
import txt from '../../utils/i18n'

const vippsOss = () => <div className="vipps-oss-container">
    <h3>Takk skal du ha!</h3>
    <VippsPayment />
</div>


const ResultStep = ({ taxInfo, showModal, searchTax, ...props }) => {
    const { goToStep } = useWizard();
	const taxes = taxInfo
    return <div className='wizard-step wizard-step-result'>
        <h1>
            { txt('TXT_STEP_RESULT_HEADER') }
        </h1>
        <div className='tax-info'>
            { (!taxes?.length) ? 
            <MessageView message={ {type: 'warning'} }>
            { taxInfo?.status == 500? txt('ERR_NO_TAX_FOUND_EXP', txt('ERR_MSG_TECH_SYSERR')) : txt('ERR_NO_TAX_FOUND_EXP', txt('ERR_MSG_BAD_REQUEST')) }
            </MessageView> : 
            <span className='valid-tax-info'>
            	{taxes.map((t, idx) => <TaxInfo key={ idx } tax={ t } searchTax={ searchTax } expanded={ idx == 0 } />)}
            </span>
        	}
        </div>
        <div className="form-action-line">
            { taxes && taxes.length > 0 && <button className='btn btn-primary' onClick={ () => showModal(vippsOss()) }>
                { txt('TXT_STEP_SPONSOR_US') }
            </button> }
            <button className='btn btn-primary' onClick={ () => goToStep(1) }>
                { txt('TXT_BUTTON_NEW_SEARCH') }
            </button>
        </div>
    </div>
}

ResultStep.propTypes = {
    taxInfo: PropTypes.array.isRequired
}

export default ResultStep