const no = {__name__: 'Norsk'}, en = {__name__: 'English'}


no.TAX_STEP_INTRO_HEADER = 'KAN MAN SØKE NABOENS SKATTEINFO ANONYMT?'
en.TAX_STEP_INTRO_HEADER = "CAN MAN SEARCH HIS NEIGHBOR'S TAX INFO ANONYMOUSLY?"

no.TAX_INFO_TITLE = 'Sjekk skattelister ANONYMT, AUTOMATISK'
en.TAX_INFO_TITLE = 'Check tax info ANONYMOUSLY, AUTOMATICALLY'

no.TXT_BUTTON_NEXT = 'Neste'
en.TXT_BUTTON_NEXT = 'Next'

no.TXT_BUTTON_JUMP = 'Hopp over'
en.TXT_BUTTON_JUMP = 'Skip'

no.TXT_BUTTON_SEARCH_ANON = 'Søk anonymt'
en.TXT_BUTTON_SEARCH_ANON = 'Search ANONYMOUSLY'

no.TXT_BACK = 'Tilbake'
en.TXT_BACK = 'Back'

no.TXT_BUTTON_CANCEL = 'Avbryt'
en.TXT_BUTTON_CANCEL = 'Cancel'


no.TXT_BUTTON_CONFIRM = 'Bekreft'
en.TXT_BUTTON_CONFIRM = 'Confirm'

no.TXT_BUTTON_NEW_SEARCH = 'Nytt Søk'
en.TXT_BUTTON_NEW_SEARCH = 'New Search'

no.TXT_BUTTON_FETCH_TAX = 'Hent skatte'
en.TXT_BUTTON_FETCH_TAX = 'Fetch tax'

no.TXT_BUTTON_CLOSE = 'Lukk'
en.TXT_BUTTON_CLOSE = 'Close'

no.TXT_BUTTON_ACCEPT_ALL = 'Godta alle'
en.TXT_BUTTON_ACCEPT_ALL = 'Accept all'

no.TXT_BUTTON_CONTINUE = 'Fortsett'
en.TXT_BUTTON_CONTINUE = 'Continue'

no.TXT_INPUT_FIRSTNAME_LABLE = 'Førstnavn'
en.TXT_INPUT_FIRSTNAME_LABLE = 'Firstname'

no.TXT_INPUT_LASTNAME_LABLE = 'Etternavn'
en.TXT_INPUT_LASTNAME_LABLE = 'Surename'

no.TXT_TAX_LBL_BIRTHYEAR = 'Fødselsår'
en.TXT_TAX_LBL_BIRTHYEAR = 'Birth year'

no.TXT_TAX_LBL_POSTCODE = 'Postnummer'
en.TXT_TAX_LBL_POSTCODE = 'Post code'

no.TXT_TAX_LBL_POSTPLACE = 'Poststed'
en.TXT_TAX_LBL_POSTPLACE = 'Post place'

no.TXT_TAX_LBL_EARNINGS = 'Inntekt'
en.TXT_TAX_LBL_EARNINGS = 'Incomes'

no.TXT_TAX_LBL_TAX = 'Beregnet skatt'
en.TXT_TAX_LBL_TAX = 'Calculated tax'

no.TXT_TAX_LBL_ASSETS = 'Formue'
en.TXT_TAX_LBL_ASSETS = 'Assets'

no.TXT_TAX_LBL_TAXPLACE = 'Skattekommune'
en.TXT_TAX_LBL_TAXPLACE = 'Tax place'

no.TXT_TAX_LBL_YEAR = 'Inntektsåret'
en.TXT_TAX_LBL_YEAR = 'Income year'

no.ERR_VALIDATE_MSG_NAME_LENGTH = 'Navn må være minst to tegner, maks 50'
en.ERR_VALIDATE_MSG_NAME_LENGTH = 'Name should be at least 2 characters, max 50 ones'

no.ERR_NO_TAX_FOUND_EXP = 'Ingen skatteopplysninger ble funnet pga %s'
en.ERR_NO_TAX_FOUND_EXP = 'No tax data is found due to %s'

no.ERR_MSG_BAD_REQUEST = 'ugyldig informasjon om skattebetaleren'
en.ERR_NO_TAX_FOUND_EXP = 'bad taxpayer information'

no.ERR_MSG_TECH_SYSERR = 'teknisk systemfeil'
en.ERR_MSG_TECH_SYSERR = 'technical system error'

no.ERR_SEARCH_TAX_MSG = 'Det klarte ikke å hente skatteinformasjon. Vennligst prøv igjen senere eller <a href="mailto:support@taxee.no">kontakt oss</a>'
en.ERR_SEARCH_TAX_MSG = 'It failed to retrieve tax info. Please try it later or <a href="mailto:support@taxee.no">contact us</a>'

no.EXCEED_SEARCH_LIMIT = 'Din søkegrense er nådd. Vennligst vent et øyeblikk og prøv igjen senere.'
en.EXCEED_SEARCH_LIMIT = "You've reached the search limit for the next 15 minutes. Please wait a moment and try again later."

no.TXT_INPUT_BIRTHYEAR_LABLE = 'Fødselsår'
en.TXT_INPUT_BIRTHYEAR_LABLE = 'Birth Year'

no.TXT_INPUT_TAXPLACE_LABLE = 'Skattekommune'
en.TXT_INPUT_TAXPLACE_LABLE = 'Tax municipality'

no.TXT_INPUT_POSTPLACE_LABLE = 'Poststed'
en.TXT_INPUT_POSTPLACE_LABLE = 'Post place'

no.TXT_INPUT_BIRTHYEAR_HINTS = '1988, fire sifre'
en.TXT_INPUT_BIRTHYEAR_HINTS = '1988, four digits'

no.TXT_STEP_PAYER_HEADER = 'Skattebetaler opplysninger'
en.TXT_STEP_PAYER_HEADER = 'Tax payer information'

no.TXT_STEP_VIPPS_HEADER = 'Doner jobben vår med Vipps'
en.TXT_STEP_VIPPS_HEADER = 'Donate our work with Vipps'

no.TXT_STEP_RESULT_HEADER = 'Skatte søkeresultat'
en.TXT_STEP_RESULT_HEADER = 'Tax search results'

no.TXT_STEP_INTRO_DESC = 'Skatte Proxy'
en.TXT_STEP_INTRO_DESC = 'Tax Proxy'

no.TXT_STEP_SPONSOR_DESC = 'Donering med Vipps'
en.TXT_STEP_SPONSOR_DESC = 'Donate with Vipps'


no.TXT_STEP_SPONSOR_US = 'Doner oss'
en.TXT_STEP_SPONSOR_US = 'Donate us'

no.TXT_STEP_PAYER_DESC = 'Skattebetaler opplysninger'
en.TXT_STEP_PAYER_DESC = 'Taxpayer info'

no.TXT_STEP_RESULT_DESC = 'Skattedetaljer'
en.TXT_STEP_RESULT_DESC = 'Tax details'

no.TXT_INPUT_MOBILE_LABLE = 'De siste tre sifrene av mobilnummeret ditt'
en.TXT_INPUT_MOBILE_LABLE = 'The last three digits of your mobie number'

no.TXT_INPUT_MOBILE_HINTS = 'de siste 3 sifrene, *** ** 985'
en.TXT_INPUT_MOBILE_HINTS = 'the last 3 digits, *** ** 985'

no.TXT_INPUT_VIPPS_LABLE = 'Jeg har donert deres jobb for å holde tjenestene annonsefrie'
en.TXT_INPUT_VIPPS_LABLE = 'I have donated your work to keep the services ad-free'

no.TXT_PRIVACY_STATEMENT_DISC = 'Vi benytter informasjonskapsler (cookies🍪) på www.taxee.no for å gi deg en best mulig opplevelse og for å forbedre våre tjenester. Ved å bruke www.taxee.no, samtykker du til vår '
en.TXT_PRIVACY_STATEMENT_DISC = 'We use cookies🍪 on www.taxee.no to provide you with the best possible experience and to enhance our services. By using www.taxee.no, you agree to our '

no.TXT_PRIVACY_STATEMENT = 'personvernerklæring.'
en.TXT_PRIVACY_STATEMENT = 'privacy policy'

no.TXT_TAX_INTRO_CONVERSATIONS = `Naboen vår har skaffet seg en ny BYD Tang elbil.
Hvordan kan de som arbeidstakere tjene såpass mye?
Kanskje burde vi sjekke hos skatteetaten.
Jasså, de har tjent utrolig mye.
Hmm, hva om de finner ut at vi har søkt på dem?
De kan bli irriterte på oss.
Fint om vi kunne sjekket inntekten deres ANONYMT.
Joda, ingen bekymringer, takket være WWW.TAXEE.NO.`
en.TXT_TAX_INTRO_CONVERSATIONS = `Our neighbor has gotten a new BYD Tang EV.
How can they, as employees, earn that much?
Maybe we should check with the tax office.
Oh, they have earned an incredible amount.
Hmm, what if they find out that we have looked them up?
They might get annoyed with us.
It would be nice if we could check their income ANONYMOUSLY.
Sure, no worries, thanks to WWW.TAXEE.NO`

no.TXT_VIPPS_DONATION_EXP = 'Vi gir deg friheten til å selv bestemme hvor mye du ønsker å donere til oss, i stedet for å pålegge deg å betale en fast høy sum per søk, slik andre konkurrenter gjør.'
en.TXT_VIPPS_DONATION_EXP = 'We give you the freedom to decide for yourself how much you want to donate to us, instead of imposing a fixed high fee per search, as other competitors do.'

no.TXT_TAXEE_PROXY_EXP = 'WWW.TAXEE.NO fungerer som en <b>PROXY</b> mellom Deg og Skatteetaten.no. Denne Proxy-en holder deg <i>ANONYM</i> over naboene og  kollegaene dine!'
en.TXT_TAXEE_PROXY_EXP = 'WWW.TAXEE.NO works as a <b>PROXY</b> between you and Skatteetaten.no. This proxy keeps you <i>ANONYMOUS</i> from your neighbors and collegues!'

no.TXT_HTML_ABOUT_US = `<div>
<h2>Om TAXEE.NO</h2>
<div>
    WWW.TAXEE.NO fungerer som en PROXY mellom deg og Skatteetaten.no. Når du søker på skatteopplysninger hos WWW.TAXEE.NO, formidler TAXEE søket ditt til www.skatteetaten.no under vårt eget navn. Dine naboer eller kollegaer vil se at dette søket kommer fra oss. Derfor kan denne PROXY-en holde deg ANONYM når du søker på deres skatteopplysninger hos WWW.TAXEE.NO.<br/><br/>

Tjenesten du nå benytter på www.taxee.no er et resultat av vårt kontinuerlige harde arbeid samt kostnadene for vårt eget personvern på grunn av rollen som PROXY-en. Vi håper virkelig at det er verdt din donasjon.
</div>
</div>`
en.TXT_HTML_ABOUT_US = `<div>
<h2>Om TAXEE.NO</h2>
<div>
    WWW.TAXEE.NO fungerer som en PROXY mellom deg og Skatteetaten.no. Når du søker på skatteopplysninger hos WWW.TAXEE.NO, formidler TAXEE søket ditt til www.skatteetaten.no under vårt eget navn. Dine naboer eller kollegaer vil se at dette søket kommer fra oss. Derfor kan denne PROXY-en holde deg ANONYM når du søker på deres skatteopplysninger hos WWW.TAXEE.NO.<br/><br/>

Tjenesten du nå benytter på www.taxee.no er et resultat av vårt kontinuerlige harde arbeid samt kostnadene for vårt eget personvern på grunn av rollen som PROXY-en. Vi håper virkelig at det er verdt din donasjon.
</div>
</div>`

no.TXT_HTML_USER_TERMS = `<div>
    <h2>Generelle vilkår</h2>
    <h3>Informasjoner på WWW.TAXEE.NO</h3>
    <div>
        WWW.TAXEE.NO fungerer som en <b>PROXY</b> mellom Deg og Skatteetaten.no. Denne proxyen holder deg anonym overfor dine naboer og kollegaer når du søker etter deres skatteopplysninger på WWW.TAXEE.NO
    </div>
    <div>
        Som søkingsresultat blir både personopplysninger og skatteopplysninger avlest og konvertert fra www.skatteeten.no. Vi streber etter å ha korrekt informasjon på våre nettsider, men påtar oss ikke ansvar for eventuelle tap som følge av mangelfull eller uriktig informasjon, for eksempel feil som oppstår på grunn av programmeringsfeil underveis i henting og konvertering.</div>
    <h3>Bruker av WWW.TAXEE.NO</h3>
    <h4>Det er 16 års aldersgrense for å få søke i skattelistene!</h4>

    <div>Informasjonen, spesielt skatteopplysninger, på WWW.TAXEE.NO skal kun brukes på WWW.TAXEE.NO. Det er ikke tillatt å bruke både personopplysninger og skatteopplysninger fra søkeresultatet utenfor WWW.TAXEE.NO, for eksempel ved å sende skjermbilder av eller lagre søkeresultatet.</div>
    <div>
        Å utføre automatisk anonymt skattesøk via WWW.TAXEE.NO som en proxy er både tids- og ressurskrevende. Det er ikke tillatt å utføre web scraping på våre tjenester!
    </div>

    <h3>Bruk av eksterne ressurser</h3>
    <div>
        WWW.TAXEE.NO benytter noen tegninger generert av kunstig intelligens som illustrasjoner. Dersom det ikke er akseptabelt å bruke disse, og de må fjernes fra WWW.TAXEE.NO, vennligst <a href="mailto:support@taxee.no"> ta kontakt med oss </a>.
    </div>
</div>`
en.TXT_HTML_USER_TERMS = `<div>
    <h2>General Terms</h2>
    <h3>Information on WWW.TAXEE.NO</h3>
    <div>WWW.TAXEE.NO acts as a <b>PROXY</b> between You and Skatteetaten.no. This proxy keeps you anonymous from your neighbors and colleagues when you search for their tax information on WWW.TAXEE.NO.</div>
    <div>As search results, both personal and tax information is read and converted from www.skatteeten.no. We strive to have accurate information on our website but do not assume responsibility for any loss due to incomplete or incorrect information, such as errors that may occur due to programming defects during retrieval and conversion.</div>
    <h3>User of WWW.TAXEE.NO</h3>
    <div>Information, especially tax information, on WWW.TAXEE.NO should only be used on WWW.TAXEE.NO. It is not allowed to use both personal and tax information from the search results outside WWW.TAXEE.NO, such as by sending screenshots or storing search results.</div>
    <div>
        Performing automatic anonymous tax searches via WWW.TAXEE.NO as a proxy is both time and resource-intensive. It is not allowed to perform web scraping on our services!
    </div>

    <h3>Use of External Resources</h3>
    <div>
        WWW.TAXEE.NO uses some drawings generated by artificial intelligence as illustrations. If it is not acceptable to use these and they need to be removed from WWW.TAXEE.NO, please <a href="mailto:support@taxee.no">contact us</a>.
    </div>
</div>
`

no.TXT_HTML_PRIVACY_STATEMENT = `<dev>
    <h1>Personvern på taxee.no</h1>
    <div>
        WWW.TAXEE.NO fungerer som en <b>PROXY</b> mellom Deg, heretter kalt <b><i>Søker</i></b> og Skatteetaten.no. Denne Proxy-en holder deg ANONYM overfor naboene og kollegaene dine, heretter kalt <b><i>Motpart</i></b>, når du søker på deres skatteopplysninger hos WWW.TAXEE.NO.
    </div>
    <h3>Bruk av cookies (informasjonskapsler)</h3>
    <div class='ps-details-exp'>
        For at www.taxee.no skal fungere, må vi bruke tekniske informasjonskapsler som ikke lagres på enheten din, f.eks.
        <ul>Cookies brukes på www.taxee.no
        <li><b>PS</b> : om å vise varsling av bruk av informasjonskapsler</li>
        <li><b>curlang</b> : tilsier hvilket språke er brukt på frontend</li>
        <li><b>TXS</b> : kontroller at man ikke kan søke for mange ganger inne i en begrenset tidsperiod</li>
        </ul>

        Disse kan derfor ikke slås av
    </div>
    <h3>Hvor henter vi opplysningene fra?</h3>
    <div class='ps-details-exp'>
     Når du søker på naboene eller kollegaene dine på www.taxee.no, henter TAXEE.NO alle skatteopplysningene fra www.skatteetaten.no. Disse skatteopplysningene blir omstrukturert i JSON-formatet og sendt til nettleseren din.
    </div>
    <h3>Hvilke personopplysninger behandler vi?</h3>
    <div class='ps-details-exp'>
        For Søkeren tar TAXEE.NO kun imot de siste tre sifrene av mobilnummeret ditt for å spore når din donasjon har ankommet. De tre sifrene blir ikke lagret. Ingen av dine personopplysninger blir lagret i det hele tatt.
        <ul>For Motparten, er både skatteopplysningene og personopplysningene dine som var avhentet fra www.skatteetaten.no lagret f-formål og forbedring av ytelsen av disse grunnene::
        <li> Disse informasjonene beholder fast.</li>
        <li>Når den samme Motparten er søkt på igjen kan TAXEE.NO defor direkte servere disse lagrete opplysningene</li>
        <li>Å unngå å sende dette samme oppslaget fra TAXEE.NO kunne hjelpe minke belastninger på www.skatteetaten.no</li>
        </ul>
    </div>
    <h3>Hvordan behandler vi personopplysningene dine?</h3>
    <div class='ps-details-exp'>
       Både skatteopplysningene og personopplysningene til Motparten som var avhentet fra www.skatteetaten.no er plain text som sånn:
       <pre>
        <code class='long-text-line'>
'OLA NORDMANN\nInntektsåret 2021\nInntekt: 888 888\nFormue: 888\nBeregnet skatt: 0\n\nFødselsår:\n1980\nPostnummer/sted:0135 OSLO\nSkattekommune\nOslo'
        </code>
       </pre>
       Og de vil bli konvertert inn i JSON formatten som sånn:
       <pre>
         <code>

{
  "fullName": "OLA NORDMANN",
  "year": "2021",
  "earnings": "888888",
  "assets": "888",
  "tax": "328888",
  "birthYear": "1980",
  "postCode": "0135",
  "postPlace": "OSLO",
  "taxPlace": "Oslo"
}
        </code>
       </pre>
    </div>
    <h3>Motparten har disse rettighetene til sine largrete personopplysninger</h3>
    <div class='ps-details-exp'>
        <ul>Disse rettighetene har du som Motparten
            <li>Innsyn</li>
            <li>Dataportabilitet</li>
            <li>Retting</li>
            <li>Sletting</li>
            <li>Protestere</li>
            <li>Begrensning</li>
            <li>Klage</li>
        </ul>
        Vennligst henvend deg til oss på <a href="mailto:support@taxee.no"> e-posten </a>
    </div>
<div/>`
en.TXT_HTML_PRIVACY_STATEMENT = `<div>
    <h1>Privacy at taxee.no</h1>
    <div>
        WWW.TAXEE.NO acts as a <b>PROXY</b> between You, hereafter referred to as <b><i>Seeker</i></b>, and Skatteetaten.no. This proxy keeps you ANONYMOUS from your neighbors and colleagues, hereafter referred to as <b><i>Counterparty</i></b>, when you search for their tax information on WWW.TAXEE.NO.
    </div>
    <h3>Use of Cookies</h3>
    <div class='ps-details-exp'>
        For www.taxee.no to function, we need to use technical cookies that are not stored on your device, e.g.
        <ul>Cookies used on www.taxee.no
            <li><b>PS</b>: to display notifications of cookie usage</li>
            <li><b>curlang</b>: indicates the language used on the frontend</li>
            <li><b>TXS</b>: ensures that one cannot search too many times within a limited time period</li>
        </ul>

        These cannot be turned off.
    </div>
    <h3>Where do we get the information from?</h3>
    <div class='ps-details-exp'>
        When you search for your neighbors or colleagues on www.taxee.no, TAXEE.NO retrieves all tax information from www.skatteetaten.no. This tax information is restructured in JSON format and sent to your browser.
    </div>
    <h3>What personal information do we process?</h3>
    <div class='ps-details-exp'>
        For the Seeker, TAXEE.NO only receives the last three digits of your mobile number to track when your donation has arrived. These three digits are not stored. None of your personal information is stored at all.
        <ul>For the Counterparty, both the tax and personal information retrieved from www.skatteetaten.no are stored for purposes of caching and improving performance for these reasons:
            <li>These pieces of information are kept intact.</li>
            <li>When the same Counterparty is searched again, TAXEE.NO can directly serve these stored pieces of information.</li>
            <li>Avoiding sending the same query from TAXEE.NO could help reduce the load on www.skatteetaten.no.</li>
        </ul>
    </div>
    <h3>How do we process your personal information?</h3>
    <div class='ps-details-exp'>
        Both the tax and personal information of the Counterparty, retrieved from www.skatteetaten.no, are in plain text like this:
        <pre>
            <code class='long-text-line'>
            'OLA NORDMANN\nIncome Year 2021\nIncome: 888,888\nAssets: 888\nEstimated Tax: 0\n\n' +
            'Birth Year:\n1980\nPostal Code/Place: 0135 OSLO\nTax Municipality\nOslo'
            </code>
        </pre>
        And they will be converted into JSON format like this:
        <pre>
            <code>
{
  "fullName": "OLA NORDMANN",
  "year": "2021",
  "earnings": "888888",
  "assets": "888",
  "tax": "328888",
  "birthYear": "1980",
  "postCode": "0135",
  "postPlace": "OSLO",
  "taxPlace": "Oslo"
}
            </code>
        </pre>
    </div>
    <h3>The Counterparty has these rights to their stored personal information</h3>
    <div class='ps-details-exp'>
        <ul>These rights you have as the Counterparty:
            <li>Access</li>
            <li>Data portability</li>
            <li>Rectification</li>
            <li>Deletion</li>
            <li>Objection</li>
            <li>Limitation</li>
            <li>Complaint</li>
        </ul>
        Please contact us via <a href="mailto:support@taxee.no">email</a>.
    </div>
</div>
`


no.TXT_STEP_VIPPS_SPONSOR_HEADER = 'Sponser oss ved å åpne Vipps mobil App-en din'
en.TXT_STEP_VIPPS_SPONSOR_HEADER = 'Sponsor us by opening your Vipps mobile App'

no.TXT_STEP_VIPPS_SPONSOR_QRCODE = 'Skanne Vipps QR-koden nedenfor'
en.TXT_STEP_VIPPS_SPONSOR_QRCODE = 'Scan the Vipps QR code below'

no.TXT_STEP_VIPPS_SPONSOR_MOBILE = 'Eller send penger til mobilnummeret'
en.TXT_STEP_VIPPS_SPONSOR_MOBILE = 'Alternatively send money to the mobile number'

no.ERR_NO_EMPTY_FIELD = 'Feltet må fylles ut';
en.ERR_NO_EMPTY_FIELD = 'The field cannot be empty';

no.ERR_VALIDATE_MSG_BIRTHYEAR = 'Fødsår er 4 sifre, f.eks 1988';
en.ERR_VALIDATE_MSG_BIRTHYEAR = 'Birth year is 4 digits, e.g 1988';

no.ERR_VALIDATE_MSG_VIPPS_PAYMENT = 'Vennligst donere oss';
en.ERR_VALIDATE_MSG_VIPPS_PAYMENT = 'Please donate our work';

no.ERR_VALIDATE_MSG_MOBILE_LENGTH = 'De siste tre sifre på mobilenummeret ditt'
en.ERR_VALIDATE_MSG_MOBILE_LENGTH = "The last three digits of your phone number"


export default {
    no,
    en
}